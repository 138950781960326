import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';

interface BaseAppContextProps {
	isEligible: boolean;
	showModal: boolean;
	isOptOut: boolean;
	setEligibility: (isEligible: boolean) => void;
	setShowModal: (show: boolean) => void;
	setIsOptOut: (isOptOut: boolean) => void;
	showOptOutModal: boolean;
	setShowOptOutModal: (show: boolean) => void;
}

const defaultValue: BaseAppContextProps = {
	isEligible: false,
	showModal: true,
	setEligibility: () => {},
	setShowModal: () => {},
	isOptOut: false,
	setIsOptOut: () => {},
	showOptOutModal: true,
	setShowOptOutModal: () => {},
};

const BaseAppContext = createContext<BaseAppContextProps>(defaultValue);

export const useEligibility = () => useContext(BaseAppContext);

const isBrowser = typeof window !== 'undefined';

interface BaseProps {
	children: ReactNode;
}

export const BaseAppContextProvider: React.FC<BaseProps> = ({ children }) => {
	const [isEligible, setIsEligible] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(true);
	const [isOptOut, setOptout] = useState<boolean>(false);
	const [showOptOutModal, setShowOptOutModal] = useState<boolean>(false);

	useEffect(() => {
		if (isBrowser) {
			const storedOptoutChoice = localStorage.getItem('isOptOut');
			if (!storedOptoutChoice) {
				localStorage.setItem('isOptOut', JSON.stringify(false));
			} else {
				setIsOptOut(JSON.parse(storedOptoutChoice));
			}
		}
	}, []);

	useEffect(() => {
		if (isBrowser) {
			const storedEligibility = localStorage.getItem('isEligible');
			if (storedEligibility) {
				setIsEligible(JSON.parse(storedEligibility));
				setShowModal(false);
			}
		}
	}, []);

	const setIsOptOut = (newOptout: boolean) => {
		setOptout(newOptout);

		if (isBrowser) {
			localStorage.setItem('isOptOut', JSON.stringify(newOptout));
		}
		setShowOptOutModal(false);
	};

	const setEligibility = (newEligibility: boolean) => {
		setIsEligible(newEligibility);
		if (isBrowser) {
			localStorage.setItem('isEligible', JSON.stringify(newEligibility));
		}
		setShowModal(false);
	};

	return (
		<BaseAppContext.Provider
			value={{
				isEligible,
				showModal,
				setShowModal,
				setEligibility,
				isOptOut,
				setIsOptOut,
				showOptOutModal,
				setShowOptOutModal,
			}}
		>
			{children}
		</BaseAppContext.Provider>
	);
};
